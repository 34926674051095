var debugMode = false;

(function () {
  var main = {
    init: function () {

      !debugMode || console.log('Main.js');

      this.bindEvents();
      this.productOptions();
      this.headerStick();
      // this.headerToggle();
      this.blog();
      this.brandSlider();
      this.myAccount();
      // this.mobileMenu();
      this.quickAdd();
      //this.quickOrder();
      this.sortProductList();
        /*this.selectAddress();*/

    },
/*
      selectAddress: function(){

          $("#address_select").change(function(e) {
              alert("dwqw");
                  $.ajax({
                      url: "/account/addresses/get",
                      type: 'GET',
                      data: "q=" + $("#address_select").val(),
                      dataType: 'json',
                      error: function (response) {
                          alert(response);
                      },
                      success: function (response) {

                          $("#shipping_address_line_1").val(response.address_line_1);
                          $("#shipping_address_line_2").val(response.address_line_2);
                          $("#shipping_city").val(response.city);
                          $("#shipping_postcode").val(response.postcode);
                      }
                  });

          });


      },
      */

      sortProductList: function() {

          $("#sort_product_list").change(function(e) {
              e.preventDefault();
              var url = window.location.href;
              if(url.search("\\?") == -1) {
                  window.location = "?sort_by=" + $(this).val();
              }else{
                  window.location = window.location.href+"&sort_by=" + $(this).val();
              }

          });

      },


      quickOrder: function() {

          $("#quick_order_search").keyup(function(e) {
              e.preventDefault();
              if($("#quick_order_search").val() != "") {
                  $.ajax({
                      url: "/quick-order-items",
                      type: 'GET',
                      data: "q=" + $("#quick_order_search").val(),
                      contentType: 'application/HTML',
                      error: function (response) {
                          console.log(response);
                      },
                      success: function (response) {
                          $("#product_option_container").html(response);
                      }
                  });
              }

          });

      },

    quickAdd: function() {
      $('.quick-order').on('click', function() {
        $('.quick-order-pop').toggleClass('active');
      });

      $(document).keyup(function(e) {
        if (e.keyCode === 27 && $('.quick-order-pop').hasClass('active')) $('.quick-order-pop').removeClass('active');
      });

      $(document).mouseup(function (e) {
        var container = $(".form-wrap");

        if (!container.is(e.target) // if the target of the click isn't the container...
            && container.has(e.target).length === 0) // ... nor a descendant of the container
        {
            $('.quick-order-pop').removeClass('active');
        }
      });


      // Add Row To Quick Add
      var i = 3;
      $('.add-row').on('click', function() {
        i++
        var rowHtml = '<div class="order-row"><input type="text" name="item-number-' +i+ '" placeholder="Enter Item SKU"><input type="text" class="qty" name="item-qty-' +i+ '" placeholder="Qty"></div>';
        $('.order-row-wrap').append(rowHtml);
      });
    },

    mobileMenu: function() {
      $('.toggle-menu').on('click', function() {
        $('ul.top-level').toggleClass('active');
        $('body').toggleClass('makeFixed');
      });

      $('.search-toggle').on('click', function() {
        $(this).parent().find('.search-drop').toggleClass('active');
      });

      $('a.dropdown').click(function(e) {
        var listItem = $(this).parent();
        if (!listItem.is('.active')) {
            e.preventDefault();
            listItem.addClass('active');
        }
      });

      $('.mobile-toggle').click(function(e) {
        $(this).parent().removeClass('active');
      });

      $('.mobile-filter, .close-mobile, .apply-mobile').on('click', function() {
        $('.filter-list').toggleClass('active-mobile');
        $('body').toggleClass('makeFixed');
      });
    },

    brandSlider: function() {
      var $window = $(window),
      flexslider = { vars:{} };

      function getGridSize() {
        return (window.innerWidth < 400) ? 1 :
        (window.innerWidth < 600) ? 2 :
        (window.innerWidth < 700) ? 3 :
        (window.innerWidth < 1000) ? 4 : 5;
      }

      $window.load(function() {
        $('.brands').flexslider({
          animation: "slide",
          animationLoop: true,
          itemWidth: 210,
          itemMargin: 0,
          controlNav: true,
          directionNav: false,
          minItems: getGridSize(),
          maxItems: getGridSize(),
          start:      function(slider){
            flexslider = slider;
          }
        });
      });

      $window.resize(function() {
        var gridSize = getGridSize();

        flexslider.vars.minItems = gridSize;
        flexslider.vars.maxItems = gridSize;
      });
    },

    headerToggle: function() {
      $('.toggle').hover( function(e){
        $(this).toggleClass('active-drop');
        e.preventDefault();
      });
    },

    blog: function() {
      var $grid = $('.blog-listing').packery({
        itemSelector: '.grid-item',
        gutter:0
      });

      $grid.imagesLoaded().progress( function() {
        $grid.packery();
      });
    },

    myAccount: function() {
      $("#clogo").change(function(){
        var fileName = $(this).val().split('/').pop().split('\\').pop();
        $('.file').html(fileName);
        $(this).parent().addClass('selected');
      });

      $('#my-details').validate();
      $('#update-password').validate();
      $('#add-address-form').validate({
        highlight: function(element) {
          $(element).parent().addClass("error");
        },
        unhighlight: function(element) {
          $(element).parent().removeClass("error");
        },
        submitHandler: function(){
          var inst = $('[data-remodal-id=add-address]').remodal();
          inst.close();
              // $.ajax();
            }
          });
      $("select").on("select2:close", function (e) {
        $(this).valid();
      });
    },

    headerStick: function () {
      $(window).on("scroll", function() {
        var fromTop = $(window).scrollTop();
        $("body").toggleClass("down", (fromTop > 100));
      });
    },

    bindEvents: function () {

        $('.brand_filter_checkbox').iCheck({
            checkboxClass: 'icheckbox_minimal',
            radioClass: 'iradio_minimal',
            increaseArea: '20%' // optional
        }).on('ifChanged', function(){
            $(this).parents('form').submit();
        });

        $('.child_category_filter_checkbox').iCheck({
            checkboxClass: 'icheckbox_minimal',
            radioClass: 'iradio_minimal',
            increaseArea: '20%' // optional
        }).on('ifChanged', function(){
            $(this).parents('form').submit();
        });

      $('select').select2({
        minimumResultsForSearch: 5
      });

      $('.filter-brand').click(function() {
        $('.brand-list').toggle();
      });

      $('#slider-new').flexslider({
        animation: "slide",
        controlNav: false,
        animationLoop: true,
        slideshowSpeed: 4000,
        directionNav: true,
        start: function(slider){
          $('#carousel-new li').mouseover(function(){
           var activeSlide = 'false';
           if ($(this).hasClass('flex-active-slide')){
            activeSlide = 'true';
          }
          if (activeSlide == 'false'){
            $(this).trigger("click");
          }
        });
          $('#carousel-new li a').click(function(){
            window.location= $(this).attr("href");
          });
        }
      });

      $('.social').flexslider({
        directionNav:false,
        controlNav:false
      });

      $('#register').validate({
        highlight: function(element) {
          $(element).parent().addClass("error");
        },
        unhighlight: function(element) {
          $(element).parent().removeClass("error");
        }
      });
      $('#signin').validate();

      /* Product Image Zoom */
      $('.zoom-img').hover(function(){
       if($(window).width()>1024){
        $(this).elevateZoom({
          zoomType: "inner",
          cursor: "pointer"
        });
      }
      else{
        $.removeData($(this), 'elevateZoom');
        $('.zoomContainer').remove();
        return false;
      }
    });

      $(":checkbox").on('click', function(){
        $(this).parent().toggleClass("checked");
      });
    },

    productOptions: function () {
      $('#product-carousel').flexslider({
        animation: "slide",
        controlNav: false,
        animationLoop: false,
        slideshow: false,
        directionNav: true,
        asNavFor: '#product-slider',
        itemMargin: 5,
        itemWidth: 50
      });

      // Slider
      $('#product-slider').flexslider({
        animation: "slide",
        controlNav: false,
        animationLoop: false,
        sync: "#product-carousel",
        directionNav: false,
        slideshow: false,
        touch: true
      });

      var radioButtons = $('input[type="radio"].product-colour');
      radioButtons.click(function() {
        radioButtons.each(function() {
          $(this).parent().toggleClass('checked', this.checked);
        });

        var imgSrc = $(this).attr("data-slide")-1;
        $('#product-slider').data("flexslider").flexAnimate(imgSrc);
      });

      $('.size-input input').blur(function () {
        var sum = 0,
        button = $('.add-zone button');

        $('.size-input input').each(function() {
          sum += Number($(this).val());
        });

        if (sum == 0) { button.addClass('inactive'); } else { button.removeClass('inactive'); }
        button.find('span').text(sum);
      });

      $(".tabs-menu a").click(function(event) {
        event.preventDefault();
        $(this).parent().addClass("current");
        $(this).parent().siblings().removeClass("current");
        var tab = $(this).attr("href");
        $(".tab-content").not(tab).css("display", "none");
        $(tab).fadeIn();
      });
    }

  }

  main.init();

})();


// MOBILE MENU
$(document).ready(function () {
    $('nav.normal').meanmenu({
        meanScreenWidth: '1024',
        meanMenuContainer: '.mobile-menu .toggle',
    });
});